import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import ReactLoading from 'react-loading';
import { RootState } from 'handlers';
import { uploadDocuments } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import DropzoneWidget, { useFiles } from 'components/DropzoneWidget';
import { getApplicationData } from 'selectors/getApplicationData';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { ButtonType } from 'components/Button/Button';
import { DigifiDocumentType } from 'api/DocumentsApi';
import { StepComponent } from 'components/Steps/stepUtils';
import { StepsResult } from 'enums/FlowNextResults';
import { DROPBOX_EXTENSIONS } from 'components/SetupDeduction/ManualDeduction/components/VerifyPayrollDeduction/VerifyPayrollDeduction';
import { useVerificationSteps } from 'components/Verification/verificationSteps';

import styles from './UploadPaystubs.module.scss';

const FilesPreview = React.lazy(() => import('components/FilesPreview'));

const renameFiles = (cb: (files: File[]) => void, filePrefix?: string) => (files: File[]) => {
  const prefix = filePrefix ? `${filePrefix} ` : '';
  return cb(files.map((file) => new File([file], `${prefix}${file.name}`, { type: file.type })));
};

const UploadPaystubs = ({ handleNext }: StepComponent) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { handleAddFiles, files, handleRemoveFiles } = useFiles();
  const { isLastStep } = useVerificationSteps();

  const { id: applicationId } = useSelector(getApplicationData).application!;
  const { isApplicationStatusUpdating } = useSelector(getApplicationData);
  const { isLoading } = useSelector((state: RootState) => state.filesUpload);

  const handleContinue = async () => {
    const filesForUpload: File[] = files.map((item) => item.file);

    analytics.track('Upload Paystubs in Progress');
    await dispatchWithUnwrap(
      uploadDocuments({ applicationId, files: filesForUpload, fileType: DigifiDocumentType.Paystub }),
    );

    handleNext();
  };

  return (
    <FormContainer
      title="Upload Paystubs"
      subtitle="Please upload your two most recent paystubs to help us verify your income."
      className={styles.formContainer}
    >
      <DropzoneWidget handleFiles={renameFiles(handleAddFiles, 'Paystub')} extensions={DROPBOX_EXTENSIONS} />
      <ul className={styles.filesRequirementsList}>
        <li>Acceptable file formats are PDF, JPG, GIF and PNG</li>
        <li>Maximum file size 10MB</li>
      </ul>
      {files.length ? (
        <Suspense fallback={<ReactLoading type="spin" color="#fff" />}>
          <FilesPreview files={files} handleRemove={handleRemoveFiles} />
        </Suspense>
      ) : (
        <></>
      )}
      <Button
        disabled={!files.length}
        className={styles.button}
        onClick={handleContinue}
        isLoading={isLoading || isApplicationStatusUpdating}
      >
        Next
      </Button>
      {!isLastStep && (
        <Button onClick={() => handleNext(StepsResult.DoThisLater)} type={ButtonType.Secondary}>
          Do this later
        </Button>
      )}
    </FormContainer>
  );
};

export default withLDConsumer()(UploadPaystubs);
