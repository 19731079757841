import React from 'react';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import { FlowComponentType } from 'routes/FlowRouter';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import ImportBalance from 'components/Common/ImportBalance/ImportBalance';
import { useSelector } from 'react-redux';
import { getPreQualificationData } from 'selectors/preQualificationData';

import { ReactComponent as BankIcon } from 'images/bank.svg';
import { ReactComponent as PlanneryIcon } from 'images/plannery.svg';
import { ReactComponent as ArrowNext } from 'images/arrow-next.svg';
import { getCardData } from 'selectors/getCardData';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getApplicationData } from 'selectors/getApplicationData';
import { joinDebtConsolidationWaitlist } from 'thunks';
import { getDebtConsolidationData } from 'selectors/getDebtConsolidationData';
import { DebtConsolidationEarlyAccessResult } from 'enums/DebtConsolidationResults';
import { PARTNER_DATA, PartnerName, PartnerType } from 'enums/PartnerName';

import styles from './EarlyAccess.module.scss';

const getLabels = (partnerName?: PartnerName, cardApplied?: boolean) => {
  // TODO: (use “employees” or “members” depending on the type of partner)
  const partnerData = partnerName ? PARTNER_DATA[partnerName] : undefined;
  if (partnerData?.type === PartnerType.Employer) {
    return {
      title: (
        <>
          Priority Access,
          <br /> Just for {partnerData.shortName}
        </>
      ),
      subtitle: (
        <div className={styles.subtitleContainer}>
          <p>We're planning to launch our next financial assistance fund in early 2024.</p>
          <p>{partnerData.shortName} employees get priority access.</p>
        </div>
      ),
    };
  }

  if (cardApplied) {
    return {
      title: (
        <>
          Priority Access,
          <br /> Just for You
        </>
      ),
      subtitle: (
        <div className={styles.subtitleContainer}>
          <p>We're planning to launch our next financial assistance fund in early 2024.</p>
          <p>Plannery Card applicants get priority access.</p>
        </div>
      ),
    };
  }

  return {
    title: 'Get Early Access',
    subtitle: <>We're planning to launch our next financial assistance fund in early 2024.</>,
  };
};
const EarlyAccess = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { partnerName } = useSelector(getPreQualificationData);
  const { applied } = useSelector(getCardData);
  const { application } = useSelector(getApplicationData);
  const { id: applicationId } = application!;
  const { title, subtitle } = getLabels(partnerName, applied);
  const { isLoading } = useSelector(getDebtConsolidationData);

  const handleJoin = async () => {
    await dispatchWithUnwrap(joinDebtConsolidationWaitlist(applicationId));
    handleNext(DebtConsolidationEarlyAccessResult.Join);
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer title={title} subtitle={subtitle}>
        <ImportBalance
          importLabel={<ArrowNext />}
          leftColumn={{
            icon: <BankIcon />,
            label: 'Your Balance',
          }}
          rightColumn={{
            icon: <PlanneryIcon />,
            label: 'Plannery',
          }}
        />

        <div className={styles.description}>
          You can bring this balance to Plannery to access below market interest rates exclusively for healthcare
          professionals.
        </div>

        <div className={styles.buttonContainer}>
          <Button disabled={isLoading} isLoading={isLoading} onClick={handleJoin}>
            Join Early Access
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => handleNext(DebtConsolidationEarlyAccessResult.NotNow)}
            type={ButtonType.Inverted}
          >
            Not Now
          </Button>
        </div>
      </FormContainer>
    </>
  );
};

export default EarlyAccess;
