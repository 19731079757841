import React from 'react';
import Button from 'components/Button';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import { FlowComponentType } from 'routes/FlowRouter';
import { ReactComponent as CheckIcon } from 'images/check-icon-rounded.svg';

const OnWaitlist = ({ navigationInfo, handleNext }: FlowComponentType) => (
  <>
    <FormNavigation {...navigationInfo} />
    <FormContainer
      icon={<CheckIcon />}
      title="You're on the list!"
      subtitle="We'll keep you informed as we get closer to the launch."
    >
      <Button onClick={handleNext}>Continue</Button>
    </FormContainer>
  </>
);

export default OnWaitlist;
