import Api, { FetchMethodType, ErrorMode } from './Api';

export interface CreateElementTokenResponse {
  entityId: string;
  elementToken: string;
}

export enum ErrorType {
  CannotRetrieveData = 'cannotRetrieveData',
  Other = 'other',
}

export interface CreateAuthSessionResponse {
  entityId: string;
  errorType?: ErrorType;
  status?: number;
}

export interface Address {
  street?: string;
  city?: string;
  stateOrProvince?: string;
  zipOrPostalCode?: string;
}

export interface CreateElementTokenData {
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber: string;
}

export interface CreateAuthSessionData extends CreateElementTokenData {
  sessionToken: string;
  birthDate?: string;
  address?: Address;
}
export interface MethodApi {
  createElementToken(credentials: CreateElementTokenData): Promise<CreateElementTokenResponse>;
  createAuthSession(credentials: CreateAuthSessionData): Promise<CreateAuthSessionResponse>;
}

export default class MethodApiRest extends Api implements MethodApi {
  public async createElementToken(data: CreateElementTokenData) {
    const response = await this.fetch<CreateElementTokenResponse>('/method/elementToken', {
      method: FetchMethodType.POST,
      body: data,
    });

    return response;
  }

  public async createAuthSession(data: CreateAuthSessionData) {
    const response = await this.fetch<CreateAuthSessionResponse>('/method/authSession', {
      method: FetchMethodType.POST,
      body: data,
      errorMode: ErrorMode.HideError,
    });

    return response;
  }
}
