import React from 'react';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import ComparisonTable from 'components/Common/Tables/ComparisonTable';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import { HardOfferData } from 'handlers/applicationData';
import { useSelector } from 'react-redux';
import { FlowComponentType } from 'routes/FlowRouter';
import { getApplicationData } from 'selectors/getApplicationData';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { ReactComponent as UnlockSavingsIcon } from 'images/lock-open.svg';
import { DebtConsolidationSummaryResult } from 'enums/DebtConsolidationResults';

import styles from './Summary.module.scss';

const Summary = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const { hardOffer } = useSelector(getApplicationData).application!;

  const { payOffTotal, planneryLoan } = hardOffer as HardOfferData;
  const { term: currentDebtTerm, amount: currentDebtAmount, financeCharge: currentDebtInterest } = payOffTotal;
  const { term: planneryLoanTerm, financeCharge: planneryLoanInterest } = planneryLoan;

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer
        icon={<UnlockSavingsIcon />}
        title="We're about to Unlock Savings for You!"
        subtitle={`Your estimated pay off plan for your ${formatMonetaryAmount(
          currentDebtAmount,
        )} in high interest debt:`}
      >
        <ComparisonTable
          comparisonLabel="vs"
          leftColumn={{
            title: 'Current Debt',
            rows: [
              {
                amount: formatMonetaryAmount(currentDebtInterest),
                label: 'Total Interest',
              },
              {
                amount: `${currentDebtTerm} months`,
                label: 'to pay off',
              },
            ],
          }}
          rightColumn={{
            title: 'With Plannery',
            rows: [
              {
                amount: formatMonetaryAmount(planneryLoanInterest),
                label: 'Total Interest',
              },
              {
                amount: `${planneryLoanTerm} months`,
                label: 'to pay off',
              },
            ],
          }}
        />

        <div className={styles.buttonContainer}>
          <Button onClick={() => handleNext(DebtConsolidationSummaryResult.Continue)}>See how it this works</Button>
          <Button onClick={() => handleNext(DebtConsolidationSummaryResult.NotNow)} type={ButtonType.Inverted}>
            Not now
          </Button>
        </div>
      </FormContainer>
    </>
  );
};

export default Summary;
