import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import FormNavigation from 'components/FormNavigation';
import RadioButtonList from 'components/Common/RadioButtonList/RadioButtonList';
import { FlowComponentType } from 'routes/FlowRouter';
import Button from 'components/Button';
import { StudentLoanEmployerResult } from 'enums/StudentLoanForgivenessFlowResults';
import { ConversionType, trackConversion } from 'utils/analytics';
import StudentLoanLoaderWrapper from 'components/StudentLoanForgiveness/LoaderWrapper';
import { updateStudentLoanAssistanceData } from 'thunks';
import { getStudentLoanData } from 'selectors/getStudentLoanData';

import QuestionsWrapper from './Wrapper/QuestionsWrapper';

import styles from './Questions.module.scss';

const options = [
  { id: StudentLoanEmployerResult.NonProfit, label: 'My employer is a non-profit or government' },
  { id: StudentLoanEmployerResult.ForProfit, label: 'My employer is a for profit company' },
  { id: StudentLoanEmployerResult.NotSure, label: "I'm not sure" },
];

const values = {
  [StudentLoanEmployerResult.ForProfit]: true,
  [StudentLoanEmployerResult.NonProfit]: false,
  [StudentLoanEmployerResult.NotSure]: null,
};

const EmployerType = ({ flags, navigationInfo, handleNext }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { applicationId } = useSelector(getStudentLoanData);
  const [selected, setSelected] = useState<StudentLoanEmployerResult | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    analytics.track('Student Loan Employer Type Selected', { selected });
    await dispatchWithUnwrap(
      updateStudentLoanAssistanceData({
        applicationId: applicationId!,
        forProfit: values[selected!],
      }),
    );
    setIsLoading(false);
    handleNext(selected!);
  };

  useEffect(() => {
    trackConversion(ConversionType.StudentLoanOfferAvailable);
  }, []);

  return (
    <StudentLoanLoaderWrapper flags={flags}>
      <>
        <FormNavigation {...navigationInfo} />
        <QuestionsWrapper>
          <>
            <RadioButtonList selected={selected} setSelected={setSelected} options={options} />
            <p className={styles.hint}>Most hospitals and universities are non-profits.</p>

            <Button disabled={Boolean(!selected)} isLoading={isLoading} onClick={handleClick} className={styles.button}>
              Next
            </Button>
          </>
        </QuestionsWrapper>
      </>
    </StudentLoanLoaderWrapper>
  );
};

export default EmployerType;
