import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import { formatDate } from 'utils/dateUtils';
import { getStudentLoanData } from 'selectors/getStudentLoanData';

import { updateStudentLoanAssistanceData } from 'thunks';
import StudentLoanLoaderWrapper from 'components/StudentLoanForgiveness/LoaderWrapper';

import QuestionsWrapper from './Wrapper/QuestionsWrapper';

import styles from './Questions.module.scss';

const NonProfitHireDate = ({ flags, navigationInfo, handleNext: onNext }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { applicationId, paymentStartDate } = useSelector(getStudentLoanData);
  const [hireDate, setHireDate] = useState<string | undefined>(paymentStartDate);
  const [isLoading, setIsLoading] = useState(false);
  const handleNext = async () => {
    setIsLoading(true);
    analytics.track('Student Loan Non-Profit Hire Date Selected', { hireDate });
    await dispatchWithUnwrap(
      updateStudentLoanAssistanceData({
        applicationId: applicationId!,
        nonProfitStartDate: new Date(hireDate!).toISOString(),
        forProfit: false,
      }),
    );
    setIsLoading(false);
    onNext();
  };

  return (
    <StudentLoanLoaderWrapper flags={flags}>
      <>
        <FormNavigation {...navigationInfo} />
        <QuestionsWrapper>
          <>
            <DatePicker
              label=" When did you start working in nonprofits or government (not specific to your current employer)?"
              selected={hireDate ? new Date(hireDate) : undefined}
              className={styles.datePicker}
              showMonthYearPicker
              placeHolder="Month YYYY"
              onChange={(date) => setHireDate(formatDate(date))}
            />
            <p className={styles.hint}>An estimate is fine here. We’ll verify this later.</p>

            <Button disabled={Boolean(!hireDate)} onClick={handleNext} isLoading={isLoading} className={styles.button}>
              Next
            </Button>
          </>
        </QuestionsWrapper>
      </>
    </StudentLoanLoaderWrapper>
  );
};

export default NonProfitHireDate;
