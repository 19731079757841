import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import Button from 'components/Button';
import { StepComponent } from 'components/Steps/stepUtils';
import FormContainer from 'components/LoanForm/FormContainer';
import Input from 'components/Input';
import { getMessageForInvalidFields, getMessageForRequiredFields } from 'utils/errors';
import { EMAIL_PATTERN } from 'components/LoanForm/YourContact/YourContact';
import { StepsResult } from 'enums/FlowNextResults';
import { getStudentLoanApplication } from 'selectors/getStudentLoanApplication';
import { updateStudentLoanApplication } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';

import styles from './EmploymentDetails.module.scss';

enum Field {
  EmployerName = 'Employer name',
  HrContactName = 'HR contact name',
  HrContactEmail = 'HR contact email address',
}

const EmploymentDetails = ({ handleNext }: StepComponent) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const {
    applicationId,
    applicationData: { employerName, hrContactName, hrContactEmail },
  } = useSelector(getStudentLoanApplication);

  const [isLoading, setIsLoading] = useState(false);

  const {
    formState: { errors, isValid },
    trigger,
    register,
    watch,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      [Field.EmployerName]: employerName,
      [Field.HrContactName]: hrContactName,
      [Field.HrContactEmail]: hrContactEmail,
    },
  });
  const watcher = watch();

  useEffect(() => {
    register(Field.EmployerName, {
      required: getMessageForRequiredFields(Field.EmployerName),
    });
    register(Field.HrContactName, {
      required: getMessageForRequiredFields(Field.HrContactName),
    });
    register(Field.HrContactEmail, {
      required: getMessageForRequiredFields(Field.HrContactEmail),
      pattern: {
        message: getMessageForInvalidFields(Field.HrContactEmail),
        value: EMAIL_PATTERN,
      },
    });
  }, [register, watcher]);

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as Field, event.target.value.trim());
    trigger(event.target.name as Field);
  };

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as Field, event.target.value);
    trigger(event.target.name as Field);
  };

  const onNext = async () => {
    setIsLoading(true);

    await dispatchWithUnwrap(
      updateStudentLoanApplication({
        applicationId: applicationId!,
        applicationData: {
          employerName: watcher[Field.EmployerName],
          hrContactName: watcher[Field.HrContactName],
          hrContactEmail: watcher[Field.HrContactEmail],
        },
      }),
    );

    analytics.track('Student Loan Employment Details Submitted');
    handleNext(StepsResult.Completed);
    setIsLoading(false);
  };

  return (
    <FormContainer
      title="Employment Details"
      subtitle={`This is needed to verify your employment${employerName ? ` at ${employerName}.` : '.'}`}
    >
      {!employerName && (
        <Input
          label={Field.EmployerName}
          placeholder={Field.EmployerName}
          errorMessage={errors[Field.EmployerName]?.message}
          name={Field.EmployerName}
          onBlur={onBlur}
          onChange={onChange}
          value={watcher[Field.EmployerName]}
        />
      )}
      <Input
        label={Field.HrContactName}
        placeholder={Field.HrContactName}
        errorMessage={errors[Field.HrContactName]?.message}
        name={Field.HrContactName}
        onBlur={onBlur}
        onChange={onChange}
        value={watcher[Field.HrContactName]}
      />
      <Input
        label={Field.HrContactEmail}
        placeholder="hr@example.com"
        errorMessage={errors[Field.HrContactEmail]?.message}
        name={Field.HrContactEmail}
        onBlur={onBlur}
        onChange={onChange}
        value={watcher[Field.HrContactEmail]}
      />
      <Button disabled={!isValid} className={styles.button} onClick={onNext} isLoading={isLoading}>
        Save and Continue
      </Button>
    </FormContainer>
  );
};

export default EmploymentDetails;
