import { DebtConsolidationResults } from './DebtConsolidationResults';
import { StudentLoanForgivenessResults } from './StudentLoanForgivenessFlowResults';

export enum YourNameResult {
  Exit = 'Exit',
}

export enum YourPhoneNumberResult {
  Exit = 'Exit',
}

export enum ApplyingResult {
  Done = 'Done',
  Error = 'Error',
  CannotRetrieveMethodData = 'cannotRetrieveMethodData',
  SessionExpired = 'sessionExpired',
}

export enum ReferralProgramResult {
  NextSteps = 'NextSteps',
  NotNow = 'NotNow',
}

export enum DontShareResult {
  Continue = 'Continue',
  Decline = 'Decline',
}

export enum CreatingOfferResult {
  Continue = 'Continue',
  ManualReview = 'ManualReview',
  OfferUnavailable = 'OfferUnavailable',
  Error = 'Error',
}

export enum ConfirmLoanResult {
  Continue = 'Continue',
  BackToYourFinances = 'BackToYourFinances',
}

export enum HowItWorksResult {
  Continue = 'Continue',
  BackToYourFinances = 'BackToYourFinances',
}

export enum LoaderResult {
  NoOffer = 'NoOffer',
  Success = 'Success',
  CantGenerateOffer = 'CantGenerateOffer',
}

export enum MakingPaymentResult {
  Success = 'Success',
  Failure = 'Failure',
}

export enum OfferStatusResult {
  BackToCard = 'BackToCard',
  BackToYourFinances = 'BackToYourFinances',
}

export enum PayOffResult {
  Continue = 'Continue',
  ManualReview = 'ManualReview',
  EmailSent = 'EmailSent',
  BackToYourFinances = 'BackToYourFinances',
}

export enum YourContactResult {
  Continue = 'Continue',
  OutOfState = 'OutOfState',
}

export enum EmploymentDetailsResult {
  Continue = 'Continue',
  Rejected = 'Rejected',
}

export enum YourSavingsResult {
  Continue = 'Continue',
  BackToYourFinances = 'BackToYourFinances',
}

export enum StepsResult {
  Completed = 'Completed',
  Rejected = 'Rejected',
  DoThisLater = 'DoThisLater',
}

export enum VerifyingApplicationResult {
  Continue = 'Continue',
  Rejected = 'Rejected',
}

export enum AlloyKycResult {
  Continue = 'Continue',
  Rejected = 'Rejected',
}

export enum CheckingAccountResult {
  Continue = 'Continue',
  Failure = 'Failure',
}

export enum ConnectPaycheckResult {
  Next = 'Next',
  NotNow = 'NotNow',
}

export type FlowNextResult =
  | YourNameResult
  | YourPhoneNumberResult
  | ApplyingResult
  | ReferralProgramResult
  | DontShareResult
  | CreatingOfferResult
  | ConfirmLoanResult
  | HowItWorksResult
  | LoaderResult
  | MakingPaymentResult
  | OfferStatusResult
  | PayOffResult
  | YourContactResult
  | EmploymentDetailsResult
  | YourSavingsResult
  | StepsResult
  | VerifyingApplicationResult
  | AlloyKycResult
  | CheckingAccountResult
  | StudentLoanForgivenessResults
  | ConnectPaycheckResult
  | DebtConsolidationResults
  | undefined;
