import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import { StudentLoanSavingsResult } from 'enums/StudentLoanForgivenessFlowResults';
import { FlowComponentType } from 'routes/FlowRouter';

import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import AreYouSureModal from 'components/StudentLoanForgiveness/Modal/AreYouSureModal';
import StudentLoanLoaderWrapper from 'components/StudentLoanForgiveness/LoaderWrapper';
import CalendlyModal from 'components/StudentLoanForgiveness/Modal/CalendlyModal';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import ComparisonTable from 'components/Common/Tables/ComparisonTable';
import { getRepaymentLabel, getDebtFreeLabels } from 'components/StudentLoanForgiveness/commonCalculations';

import AssumptionsModal from './AssumptionsModal/AssumptionsModal';

import styles from './StudentLoanSavings.module.scss';

const noPath = {
  monthsToDebtFree: NaN,
  monthlyPayment: 0,
  totalPayment: 0,
  payments: [],
};

const StudentLoanSavings = ({ flags, navigationInfo, handleNext }: FlowComponentType) => {
  const { currentPath: currentPathData, recommendedPath: recommendedPathData } = useSelector(getStudentLoanData);

  const currentPath = currentPathData ?? noPath;
  const {
    monthsToDebtFree: currentPathMonths,
    monthlyPayment: currentPathMonthlyPayment,
    totalPayment: currentPathTotalPayment,
  } = currentPath;

  const recommendedPath = recommendedPathData ?? noPath;
  const {
    monthsToDebtFree: recommendedPathMonths,
    monthlyPayment: recommendedPathMonthlyPayment,
    totalPayment: recommendedPathTotalPayment,
  } = recommendedPath;

  const { headline, value } = getRepaymentLabel(currentPath, recommendedPath);

  const [areYouSureModalOpen, setAreYouSureModalOpen] = useState(false);
  const [calendlyModalOpen, setCalendlyModalOpen] = useState(false);
  const [assumptionsModalOpen, setAssumptionsModalOpen] = useState(false);

  const showMonths =
    currentPathMonths !== undefined &&
    currentPathMonths > 0 &&
    recommendedPathMonths !== undefined &&
    recommendedPathMonths >= 0 &&
    recommendedPathMonths < currentPathMonths;
  const showMonthlyPayment = recommendedPathMonthlyPayment < currentPathMonthlyPayment;
  const showTotalPayment = recommendedPathTotalPayment < currentPathTotalPayment;

  const showTable = showMonths || showMonthlyPayment || showTotalPayment;

  const { currentPathDebtFreeLabel, recommendedPathDebtFreeLabel } = getDebtFreeLabels(
    currentPathMonths,
    recommendedPathMonths,
  );

  return (
    <StudentLoanLoaderWrapper flags={flags}>
      <>
        <CalendlyModal onSchedule={handleNext} open={calendlyModalOpen} onClose={() => setCalendlyModalOpen(false)} />
        <FormNavigation {...navigationInfo} />
        <FormContainer>
          <div className={styles.titleArea}>
            <div className={styles.title}>{headline}</div>
            {value && <p className={styles.yearsToSaveLabel}>{value}</p>}
            <p className={styles.label}>
              You likely qualify for federal student loan forgiveness. Unlock a one-time exemption on your student
              loans.
              <br />
              <strong>This program is ending soon.</strong>
            </p>
          </div>

          {(showTable || Boolean(value)) && (
            <div className={styles.payoffContent}>
              {showTable && <p className={styles.label}>Your estimated payoff:</p>}
              <div className={styles.tableAndAssumptions}>
                {showTable && (
                  <ComparisonTable
                    comparisonLabel="vs"
                    leftColumn={{
                      title: 'Current Path',
                      rows: [
                        ...(showMonths ? [{ amount: currentPathDebtFreeLabel, label: 'debt free' }] : []),
                        ...(showMonthlyPayment
                          ? [{ amount: formatMonetaryAmount(currentPathMonthlyPayment), label: 'monthly payment' }]
                          : []),
                        ...(showTotalPayment
                          ? [{ amount: formatMonetaryAmount(currentPathTotalPayment), label: 'total payment' }]
                          : []),
                      ],
                    }}
                    rightColumn={{
                      title: 'With Plannery',
                      rows: [
                        ...(showMonths ? [{ amount: recommendedPathDebtFreeLabel, label: 'debt free' }] : []),
                        ...(showMonthlyPayment
                          ? [{ amount: formatMonetaryAmount(recommendedPathMonthlyPayment), label: 'monthly payment' }]
                          : []),
                        ...(showTotalPayment
                          ? [{ amount: formatMonetaryAmount(recommendedPathTotalPayment), label: 'total payment' }]
                          : []),
                      ],
                    }}
                  />
                )}
                <Button
                  className={styles.button}
                  type={ButtonType.Secondary}
                  onClick={() => setAssumptionsModalOpen(true)}
                >
                  Update Assumptions
                </Button>
              </div>
            </div>
          )}

          <div className={styles.buttonContainer}>
            <Button className={styles.button} onClick={() => handleNext(StudentLoanSavingsResult.Next)}>
              Next
            </Button>

            <Button type={ButtonType.Inverted} onClick={() => setAreYouSureModalOpen(true)}>
              Not Now
            </Button>
          </div>

          {assumptionsModalOpen && <AssumptionsModal handleOpen={setAssumptionsModalOpen} />}
          {areYouSureModalOpen && (
            <AreYouSureModal
              onClose={() => setAreYouSureModalOpen(false)}
              onNext={() => handleNext(StudentLoanSavingsResult.Next)}
              onNotNow={() => handleNext(StudentLoanSavingsResult.NotNow)}
            />
          )}
        </FormContainer>
      </>
    </StudentLoanLoaderWrapper>
  );
};

export default StudentLoanSavings;
