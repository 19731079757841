import { useDispatch } from 'react-redux';
import { StepsResult } from 'enums/FlowNextResults';
import { RoutePath } from 'enums/Routes';
import { useNavigate } from 'hooks/useNavigate';

export type StepId = string;
export type StepProgress = Record<StepId, boolean>;

export interface StepComponent {
  handleNext(result?: any): void;
}

export interface StepData<Id extends StepId> {
  id: Id;
  name: string;
  component: React.FunctionComponent<StepComponent>;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const getStepById = <Id extends StepId>(steps: Readonly<StepData<Id>[]>, stepId: Id) =>
  steps.find(({ id }) => id === stepId) ?? steps[0];

export const getNextStep = <Id extends StepId>(
  stepsStatus: StepProgress,
  currentStep: Id,
  steps: Readonly<StepData<Id>[]>,
): Id => {
  const currentIndex = steps.findIndex((step) => step.id === currentStep);
  const nextStepsList = steps.slice(currentIndex + 1);

  let nextStep = nextStepsList.find((step) => !stepsStatus[step.id]);

  if (!nextStep) {
    nextStep = steps.find((step) => !stepsStatus[step.id]);
  }

  if (!nextStep) {
    // All steps are completed.
    nextStep = steps[currentIndex + 1];
  }

  return nextStep?.id ?? steps[0].id;
};

type StepAction<Id extends StepId> = (payload: Id) => { payload: Id; type: string };

export type UseStepsReturnType<Id extends StepId, Progress extends StepProgress> = {
  currentStep: Id;
  isLastStep: boolean;
  handleNextStep: (result?: StepsResult) => void;
  setCurrentStep: (selectedStep: Id) => void;
  firstUncompletedStep: Id;
  stepsStarted: boolean;
  stepsProgress: Readonly<Progress>;
  stepsCompleted: boolean;
  stepData: Readonly<StepData<Id>[]>;
  handleNext: () => void;
};

export const useSteps = <Id extends StepId, Progress extends StepProgress>(
  currentStep: Id,
  setCurrentStepAction: StepAction<Id>,
  completeStepAction: StepAction<Id>,
  steps: Readonly<StepData<Id>[]>,
  progress: Readonly<Progress>,
  nextRoute: RoutePath,
): UseStepsReturnType<Id, Progress> => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const nextStep = getNextStep(progress, currentStep, steps);

  const stepsUncompleted = steps.filter((step) => !progress[step.id]);
  const stepsCompleted = steps.filter((step) => progress[step.id]);

  const firstUncompletedStep: Id = stepsUncompleted[0]?.id ?? steps[0].id;
  const isLastStep = stepsCompleted.length === steps.length - 1;
  const stepsStarted = stepsCompleted.length >= 1;
  const stepsAllCompleted = stepsCompleted.length === steps.length;

  const setCurrentStep = (selectedStep: Id) => {
    dispatch(setCurrentStepAction(selectedStep));
  };

  const completeStep = (stepToComplete: Id) => {
    dispatch(completeStepAction(stepToComplete));
  };

  const handleNext = () => {
    navigate(nextRoute);
  };

  const handleNextStep = (result?: StepsResult) => {
    if (stepsCompleted && currentStep === steps[steps.length - 1].id) {
      handleNext();
      return;
    }

    if (result && result !== StepsResult.DoThisLater) {
      analytics.track(`${getStepById(steps, currentStep)?.name} Completed`);
      completeStep(currentStep);
    }

    setCurrentStep(nextStep);

    analytics.track(`${getStepById(steps, nextStep)?.name} Entered`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return {
    currentStep,
    isLastStep,
    handleNextStep,
    setCurrentStep,
    firstUncompletedStep,
    stepsStarted,
    stepsProgress: progress,
    stepsCompleted: stepsAllCompleted,
    stepData: steps,
    handleNext,
  };
};

export type StepsHook<Id extends StepId, Progress extends StepProgress> = () => UseStepsReturnType<Id, Progress>;
