import FormNavigation from 'components/FormNavigation/FormNavigation';
import { setCardData } from 'handlers/cardData';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlowComponentType } from 'routes/FlowRouter';
import { getApplicationData } from 'selectors/getApplicationData';
import { getCardData } from 'selectors/getCardData';
import { applyForCardWithApplication } from 'thunks';
import StateContainer from 'components/StateContainer';
import { ReactComponent as LoadingSpinner } from 'images/loader.svg';

import styles from './CardInfo.module.scss';

const CardInfo = ({ handleNext }: FlowComponentType): JSX.Element => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useDispatch();
  const [height, setHeight] = useState<number>(1000);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id, cardApplied } = useSelector(getApplicationData).application!;

  const data = useSelector(getCardData);

  const onLoad = () => {};

  const onMessage = (event: MessageEvent) => {
    if (event.data?.event === 'plannery.loaded') {
      setHeight(event.data.scrollHeight);
    } else if (event.data === 'plannery.cta_click') {
      handleApply();
    }
  };

  const handleApply = async () => {
    if (!cardApplied) {
      setIsLoading(true);
      const card = await dispatchWithUnwrap(
        applyForCardWithApplication({
          applicationId: id,
          referredBy: data.referredBy,
          httpReferrer: data.initialReferrer,
          resumeLink: `${window.location.href}`,
        }),
      );

      await dispatch(
        setCardData({
          applied: card.applied,
          referralLink: card.referralLink,
          referredBy: card.referredBy,
        }),
      );

      setIsLoading(false);
    }
    handleNext();
  };

  useEffect(() => {
    window.addEventListener('message', onMessage, false);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  return (
    <>
      <FormNavigation title="Plannery Card" showBackLink />
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <StateContainer icon={<LoadingSpinner />} title="Applying for Plannery Card" />
        </div>
      ) : (
        <iframe
          scrolling="no"
          onLoad={onLoad}
          height={`${height}px`}
          width="100%"
          src="https://www.planneryapp.com/card2/inline"
          title="plannery-card"
        />
      )}
    </>
  );
};

export default CardInfo;
