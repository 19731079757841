import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import Button, { ButtonType } from 'components/Button/Button';
import { getCardData } from 'selectors/getCardData';
import { getPaycheckSwitchData } from 'selectors/getPaycheckSwitchData';
import { ConnectPaycheckResult } from 'enums/FlowNextResults';
import { getPluralGroupTerm } from 'enums/ProfessionGroup';
import { FlowComponentType } from 'routes/FlowRouter';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { setPaycheckSwitchApplied } from 'handlers/paycheckSwitchData';

import { ReactComponent as Apr } from 'images/ps-apr.svg';
import { ReactComponent as Credit } from 'images/ps-credit.svg';
import { ReactComponent as Calendar } from 'images/ps-calendar.svg';
import { ReactComponent as Lock } from 'images/ps-lock.svg';
import { ReactComponent as Money } from 'images/ps-money.svg';
import { ReactComponent as Profile } from 'images/ps-profile.svg';
import { ReactComponent as Paycheck } from 'images/fast-bill.svg';
import { ReactComponent as Arrow } from 'images/arrow-rounded.svg';
import { ReactComponent as Bank } from 'images/bank-rounded.svg';
import { ReactComponent as Plannery } from 'images/plannery-logo.svg';

import styles from './ConnectPaycheck.module.scss';

const ConnectPaycheck = ({ handleNext }: FlowComponentType) => {
  const { professionGroup } = useSelector(getCardData);
  const dispatch = useDispatch();
  const { installmentPayments, interestRate, creditLimit } = useSelector(getPaycheckSwitchData);

  const descriptionItems = [
    {
      description: installmentPayments
        ? `Access installment payments, rather than revolving credit, as low as ${interestRate}% interest rate`
        : `Credit limit up to ${formatMonetaryAmount(creditLimit)}`,
      icon: installmentPayments ? Apr : Credit,
    },
    {
      description: 'Earn up to 5% cash back',
      icon: Money,
    },
    {
      description: 'Get paid up to 2 days earlier',
      icon: Calendar,
    },
  ];

  if (!installmentPayments) {
    // if installmentPayments is false, swap the position of the last two items
    [descriptionItems[1], descriptionItems[2]] = [descriptionItems[2], descriptionItems[1]];
  }

  const howItWorksItems = [
    {
      description: 'Your accounts are balanced optimally when you get paid',
      icon: Profile,
    },
    {
      description: 'Uses bank security protocols',
      icon: Lock,
    },
  ];

  return (
    <div className={styles.container}>
      <FormNavigation title="Connect Your Paycheck" />

      <FormContainer
        title="Connect Your Paycheck"
        subtitle={`Plannery card is not a credit card. It's the financial companion for ${getPluralGroupTerm(
          professionGroup!,
        )}.`}
      >
        <div className={styles.descriptionItems}>
          {descriptionItems.map((item, index) => (
            <div className={styles.item} key={index}>
              <item.icon className={styles.icon} />
              <div className={styles.itemText}>{item.description}</div>
            </div>
          ))}
        </div>
      </FormContainer>

      <FormContainer title="How it Works" className={styles.formContainerMarginTop}>
        <div className={styles.paycheckDiagram}>
          <div className={styles.card}>
            <div className={styles.row}>
              <Paycheck className={styles.cardIcon} />
              <div className={styles.cardText}>Your paycheck</div>
            </div>
          </div>

          <Arrow className={styles.arrow} />

          <div className={styles.card}>
            <div className={styles.row}>
              <Plannery className={styles.cardIcon} />
              <div className={styles.cardText}>Your card payment</div>
            </div>
            <div className={styles.plus}>Plus</div>
            <div className={styles.row}>
              <Bank className={styles.cardIcon} />
              <div className={styles.cardText}>
                <b>Remainder</b> to your existing bank account
              </div>
            </div>
          </div>
        </div>

        <div className={styles.descriptionItems}>
          {howItWorksItems.map((item, index) => (
            <div className={styles.item} key={index}>
              <item.icon className={styles.icon} />
              <div className={styles.itemText}>{item.description}</div>
            </div>
          ))}
        </div>

        <div className={styles.buttonContainer}>
          <Button
            onClick={() => {
              analytics.track('Paycheck Switch - Next');
              dispatch(setPaycheckSwitchApplied(true));
              handleNext(ConnectPaycheckResult.Next);
            }}
          >
            Next
          </Button>
          <Button
            type={ButtonType.Inverted}
            onClick={() => {
              analytics.track('Paycheck Switch - Not Now');
              handleNext(ConnectPaycheckResult.NotNow);
            }}
          >
            Not Now
          </Button>
        </div>
      </FormContainer>
    </div>
  );
};

export default ConnectPaycheck;
