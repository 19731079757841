import React from 'react';

import styles from './ImportBalance.module.scss';

interface ImportBalanceProps {
  leftColumn: ImportBalanceColumn;
  rightColumn: ImportBalanceColumn;
  importLabel: JSX.Element;
}

interface ImportBalanceColumn {
  icon: JSX.Element;
  label: string;
}

const ImportBalance = ({ leftColumn, rightColumn, importLabel }: ImportBalanceProps) => (
  <div className={styles.container}>
    <div className={styles.col}>
      <span className={styles.colIcon}>{leftColumn.icon}</span>
      <span className={styles.colLabel}>{leftColumn.label}</span>
    </div>
    <span className={styles.importLabel}>{importLabel}</span>
    <div className={styles.col}>
      <span className={styles.colIcon}>{rightColumn.icon}</span>
      <span className={styles.colLabel}>{rightColumn.label}</span>
    </div>
  </div>
);

export default ImportBalance;
