/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { StepData, StepId } from 'components/Steps/stepUtils';

import styles from './FirstTimeFlow.module.scss';

const FirstTimeFlow = <Id extends StepId>(stepData: Readonly<StepData<Id>[]>) => (
  <div className={styles.container}>
    {stepData.map((step) => (
      <div key={step.id} className={styles.step}>
        <div className={styles.stepDot} />
        <step.icon className={styles.icon} />
        <div className={styles.name}>{step.name}</div>
      </div>
    ))}
  </div>
);

export default FirstTimeFlow;
