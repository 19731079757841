import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { ReactComponent as CheckIcon } from 'images/green-check.svg';

import { StepId, StepProgress, StepsHook, getStepById } from 'components/Steps/stepUtils';
import { FlowComponentType } from 'routes/FlowRouter';
import { StepsResult } from 'enums/FlowNextResults';
import { useNavigationType } from 'hooks/useNavigate';

import styles from './StepsNavigation.module.scss';

export interface NavigationState<StepId> {
  step: StepId;
}

export const StepsNavigation = <Id extends StepId, Progress extends StepProgress>(
  { handleNext }: FlowComponentType,
  useStepsHook: StepsHook<Id, Progress>,
) => {
  const location = useLocation();
  const state = location.state as NavigationState<Id>;
  const navigationType = useNavigationType();

  const {
    currentStep,
    setCurrentStep,
    handleNextStep,
    firstUncompletedStep,
    stepsProgress,
    stepsStarted,
    stepsCompleted,
    stepData,
  } = useStepsHook();

  useEffect(() => {
    if (stepsCompleted && navigationType !== 'POP') {
      handleNext(StepsResult.Completed);
    }
  }, [stepsCompleted]);

  useEffect(() => {
    if (state?.step) {
      setCurrentStep(state.step);
      return;
    }

    if (stepsStarted) {
      setCurrentStep(firstUncompletedStep);
    }
  }, []);

  return (
    <>
      <div className={styles.navigation}>
        {stepData.map((step) => (
          <div
            onClick={() => setCurrentStep(step.id)}
            key={step.id}
            className={clsx(styles.step, {
              [styles.active]: step.id === currentStep,
            })}
          >
            <step.icon
              className={clsx(styles.icon, {
                [styles.completed]: stepsProgress[step.id],
              })}
            />
            {stepsProgress[step.id] && <CheckIcon className={styles.check} />}
          </div>
        ))}
      </div>
      <div className={styles.stepContent}>
        {React.createElement(getStepById(stepData, currentStep).component, {
          handleNext: handleNextStep,
        })}
      </div>
    </>
  );
};

export default StepsNavigation;
