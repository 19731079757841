import { createAsyncThunk } from '@reduxjs/toolkit';
import { StudentLoanApi } from 'api/StudentLoanApi';

export interface UploadStudentAidDataData {
  applicationId: string;
  file: File;
}

export const createUploadStudentAidDataThunk = (api: StudentLoanApi) =>
  createAsyncThunk('studentLoan/uploadStudentAidData', async (data: UploadStudentAidDataData, thunkApi) => {
    try {
      return await api.uploadStudentAidData(data.applicationId, data.file);
    } catch (error) {
      thunkApi.rejectWithValue(error.response.data);

      throw error;
    }
  });
