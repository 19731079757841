import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import PageLoader from 'components/PageLoader';

import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import { FlowComponentType } from 'routes/FlowRouter';
import { getStudentLoanAssistanceData } from 'thunks';

const StudentLoanLoaderWrapper = ({ children, flags }: Pick<FlowComponentType, 'children' | 'flags'>) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const studentLoanData = useSelector(getStudentLoanData);
  const { applicationId, isLoading, fetched, refetch } = studentLoanData;
  const { enableStudentLoanAssistance } = flags;

  useEffect(() => {
    if (enableStudentLoanAssistance && (isLoading || !fetched) && applicationId) {
      dispatchWithUnwrap(getStudentLoanAssistanceData({ applicationId }));
    }
  }, [applicationId]);

  if (enableStudentLoanAssistance && (isLoading || !fetched) && !refetch) {
    return <PageLoader />;
  }
  return <>{children}</>;
};

export default StudentLoanLoaderWrapper;
