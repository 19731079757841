import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Checkbox from 'components/Checkbox';
import NumberInput from 'components/NumberInput';
import { YourEmployerVariable } from 'enums/LoanFormVariables';
import { clearSecondaryEmployer, initialState, setYourEmployerData } from 'handlers/yourEmployer';
import { getMessageForRequiredFields } from 'utils/errors';

import { getYourEmployer } from 'selectors/yourEmployer';
import Input from 'components/Input';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import { ANNUAL_INCOME_MAX_LENGTH, PAY_FREQUENCY_OPTIONS } from 'components/LoanForm/YourTotalIncome/YourTotalIncome';
import Anchor from 'components/Common/Buttons/Anchor';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { updateEmploymentData } from 'thunks';
import { getApplicationData } from 'selectors/getApplicationData';
import { EmploymentData } from 'api/ApplicationDataApi';
import { NON_DIGITS_PATTERN } from 'components/Input/validation';
import { formatDate, getYearsFromNow } from 'utils/dateUtils';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { EmploymentDetailsResult } from 'enums/FlowNextResults';
import InputSelect from 'components/InputSelect';
import useCurrentFlow from 'hooks/useCurrentFlow';
import { StepComponent } from 'components/Steps/stepUtils';
import FormContainer from 'components/LoanForm/FormContainer';

import styles from './EmploymentDetails.module.scss';

enum YourEmployerInputLabel {
  EmployerName = 'employer name',
  StartOfEmployment = 'Start of employment',
  JobTitle = 'Your job title',
  AnnualIncome = 'Gross annual income',
  PayFrequency = 'Pay Frequency',
  AdditionalIncome = 'Additional income',
}

enum EmployerNumber {
  employer_1 = 'Primary',
  employer_2 = 'Secondary',
}

const EmploymentDetails = ({ handleNext }: StepComponent) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { application } = useSelector(getApplicationData);

  const { id: applicationId } = application!;

  const { isFinancialCheckupFlow } = useCurrentFlow();

  const {
    is_licensed_professional: isLicensedProfessional,
    isLoading,
    additional_income: additionalIncome,
    ...employment
  } = useSelector(getYourEmployer);
  const { employer_1: employer1, employer_2: employer2 } = employment;

  const [secondaryEmployerEnabled, setSecondaryEmployerEnabled] = useState<boolean>(
    Boolean(employer2.data.employer_name),
  );

  const [additionalIncomeEnabled, setAdditionalIncomeEnabled] = useState(Boolean(additionalIncome));

  const [licensedProfessional, setLicensedProfessional] = useState<boolean>(isLicensedProfessional);
  const {
    formState: { isValid, errors },
    trigger,
    register,
    unregister,
    watch,
    setValue,
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      employer_1: {
        ...employer1,
        data: {
          ...employer1.data,
        },
      },
      employer_2: {
        ...employer2,
        data: {
          ...employer2.data,
          start_of_employment: employer2.data.start_of_employment ? new Date(employer2.data.start_of_employment) : null,
        },
      },
      is_licensed_professional: isLicensedProfessional,
      additional_income: additionalIncome,
    },
  });

  const watcher = watch();

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as YourEmployerVariable, event.target.value.trim());
    trigger(event.target.name as YourEmployerVariable);
  };

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as YourEmployerVariable, event.target.value);
    trigger(event.target.name as YourEmployerVariable);
  };

  useEffect(() => {
    register(`employer_1.data.employer_name`, {
      required: getMessageForRequiredFields(YourEmployerInputLabel.EmployerName),
    });
    register(`employer_1.data.job_title`, {
      required: getMessageForRequiredFields(YourEmployerInputLabel.JobTitle),
    });
    register(`employer_1.data.annual_income`, {
      required: getMessageForRequiredFields(YourEmployerInputLabel.AnnualIncome),
    });

    if (isFinancialCheckupFlow) {
      register(`employer_1.data.pay_frequency`, {
        required: getMessageForRequiredFields(YourEmployerInputLabel.PayFrequency),
      });
    }

    register('additional_income', {
      required: false,
    });

    register(`is_licensed_professional`, {
      required: false,
    });

    if (secondaryEmployerEnabled) {
      register(`employer_2.data.employer_name`, {
        required: getMessageForRequiredFields(YourEmployerInputLabel.EmployerName),
      });
      register(`employer_2.data.job_title`, {
        required: getMessageForRequiredFields(YourEmployerInputLabel.JobTitle),
      });
      register(`employer_2.data.annual_income`, {
        required: getMessageForRequiredFields(YourEmployerInputLabel.AnnualIncome),
      });
      register(`employer_2.data.start_of_employment`, {
        required: getMessageForRequiredFields(YourEmployerInputLabel.StartOfEmployment),
      });
    } else {
      unregister(`employer_2.data.employer_name`);
      unregister(`employer_2.data.job_title`);
      unregister(`employer_2.data.annual_income`);
      unregister(`employer_2.data.start_of_employment`);
    }
  }, [watcher, register, secondaryEmployerEnabled, isFinancialCheckupFlow]);

  const handleContinue = async () => {
    const employmentData: EmploymentData = {
      isLicensedProfessional: watcher.is_licensed_professional,
      additionalIncome: Number(watcher.additional_income?.replace(NON_DIGITS_PATTERN, '')) || 0,
      employment: [
        {
          borrower_employer_name: watcher.employer_1.data.employer_name,
          annual_income: watcher.employer_1.data[YourEmployerVariable.AnnualIncome]
            ? Number(watcher.employer_1.data[YourEmployerVariable.AnnualIncome].replace(NON_DIGITS_PATTERN, ''))
            : undefined,
          pay_frequency: isFinancialCheckupFlow
            ? watcher.employer_1.data[YourEmployerVariable.PayFrequency]
            : application?.employment.employer1?.payFrequency!,
          payroll_provider: 'Manual',
          job_title: watcher.employer_1.data.job_title,
        },
      ],
    };

    employmentData.employment.push({
      borrower_employer_name: watcher.employer_2?.data?.employer_name || '',
      annual_income: watcher.employer_2?.data?.[YourEmployerVariable.AnnualIncome]
        ? Number(watcher.employer_2.data[YourEmployerVariable.AnnualIncome].replace(NON_DIGITS_PATTERN, ''))
        : undefined,
      pay_frequency: '',
      payroll_provider: 'Manual',
      job_title: watcher.employer_2?.data?.job_title || '',
      hire_datetime: formatDate(watcher.employer_2?.data?.start_of_employment!) || '',
    });

    const employerData = {
      ...watcher,
      employer_1: {
        ...watcher.employer_1,
        data: {
          ...watcher.employer_1.data,
        },
      },
      ...(watcher.employer_2 && {
        employer_2: {
          ...watcher.employer_2,
          data: {
            employer_name: watcher.employer_2.data.employer_name,
            job_title: watcher.employer_2.data.job_title,
            annual_income: watcher.employer_2.data.annual_income,
            pay_frequency: '',
            start_of_employment: formatDate(watcher.employer_2.data.start_of_employment!),
          },
        },
      }),
    };

    dispatch(setYourEmployerData(employerData));
    const applicationData = await dispatchWithUnwrap(
      updateEmploymentData({
        applicationId,
        employmentData,
      }),
    );

    analytics.track('Employment Details Submitted');
    if (applicationData?.application.status !== ApplicationStatusName.Rejected) {
      handleNext(EmploymentDetailsResult.Continue);
    } else {
      handleNext(EmploymentDetailsResult.Rejected);
    }
  };

  const handleAddEmployer = () => {
    setSecondaryEmployerEnabled(true);
  };

  const handleDeleteEmployer = () => {
    setSecondaryEmployerEnabled(false);
    dispatch(clearSecondaryEmployer());
    reset({
      is_licensed_professional: watcher.is_licensed_professional,
      employer_1: watcher.employer_1,
      employer_2: initialState.employer_2,
    });
  };

  const handleAddAdditionalIncome = () => {
    setAdditionalIncomeEnabled(true);
  };

  const renderEmployerInputs = (employerNumber: number) => {
    const employer = `employer_${employerNumber}` as keyof typeof employment;

    return (
      <div className={styles.inputs}>
        <Input
          label={`${EmployerNumber[employer]} ${YourEmployerInputLabel.EmployerName}`}
          placeholder="Employer name"
          errorMessage={errors?.[employer]?.data?.employer_name?.message}
          className={styles.formInput}
          name={`${employer}.data.employer_name`}
          onBlur={onBlur}
          onChange={onChange}
          value={watcher[employer]?.data[YourEmployerVariable.EmployerName]}
          autoComplete="organization"
        />
        {employerNumber === 2 && (
          <DatePicker
            maxDate={getYearsFromNow(0)}
            minDate={getYearsFromNow(-30)}
            placeHolder="MM/DD/YYYY"
            label="Start of employment"
            className={styles.formInput}
            selected={watcher.employer_2?.data[YourEmployerVariable.StartOfEmployment]}
            onChange={(date) => {
              setValue(`${employer}.data.start_of_employment`, date);
              trigger(`${employer}.data.start_of_employment`);
            }}
            errorMessage={errors?.[employer]?.data?.start_of_employment?.message}
            name={`${employer}.data.start_of_employment`}
          />
        )}
        <Input
          label={YourEmployerInputLabel.JobTitle}
          placeholder="Job title"
          errorMessage={errors?.[employer]?.data?.job_title?.message}
          className={styles.formInput}
          name={`${employer}.data.job_title`}
          onBlur={onBlur}
          onChange={onChange}
          value={watcher[employer]?.data[YourEmployerVariable.JobTitle]}
          autoComplete="organization-title"
        />
        <NumberInput
          label={YourEmployerInputLabel.AnnualIncome}
          prefix="$"
          placeholder="$0"
          errorMessage={errors?.[employer]?.data?.annual_income?.message}
          thousandSeparator
          className={styles.formInput}
          name={`${employer}.data.annual_income`}
          onBlur={onBlur}
          onChange={onChange}
          value={watcher[employer]?.data.annual_income}
          maxLength={ANNUAL_INCOME_MAX_LENGTH}
          dataNeuroLabel={`income--${employer}`}
        />
        {isFinancialCheckupFlow && employerNumber === 1 && (
          <InputSelect
            label={YourEmployerInputLabel.PayFrequency}
            options={PAY_FREQUENCY_OPTIONS}
            onChange={(option) => {
              const eventName = `${employer}.data.${YourEmployerVariable.PayFrequency}`;
              setValue(eventName, option.value);
              trigger(eventName);
            }}
            placeholder="Pay Frequency"
            className={styles.formInput}
            name={YourEmployerVariable.PayFrequency}
            value={watcher[employer]?.data[YourEmployerVariable.PayFrequency]}
          />
        )}
      </div>
    );
  };

  return (
    <FormContainer title="Employment Details" subtitle="Where do you work?">
      {renderEmployerInputs(1)}

      <Checkbox
        label="I'm a licensed healthcare professional."
        className={styles.checkbox}
        checked={watcher.is_licensed_professional ?? false}
        onChange={() => {
          setValue(`is_licensed_professional`, !licensedProfessional);
          setLicensedProfessional(!licensedProfessional);
        }}
      />

      {!secondaryEmployerEnabled && (
        <Anchor
          disabled={!isValid}
          className={styles.secondEmployerButton}
          isLoading={false}
          onClick={handleAddEmployer}
        >
          + Add another employer
        </Anchor>
      )}

      {secondaryEmployerEnabled && (
        <>
          <div className={styles.divider} />
          {renderEmployerInputs(2)}
          <Anchor className={styles.secondEmployerButton} isLoading={false} onClick={handleDeleteEmployer}>
            <div className={styles.cross}>+</div>Delete second employer
          </Anchor>
        </>
      )}

      {!additionalIncomeEnabled && (
        <Anchor
          disabled={!isValid}
          className={styles.secondEmployerButton}
          isLoading={false}
          onClick={handleAddAdditionalIncome}
        >
          + Add additional income
        </Anchor>
      )}

      {additionalIncomeEnabled && (
        <>
          <div className={styles.divider} />
          <div className={styles.inputs}>
            <NumberInput
              label={YourEmployerInputLabel.AdditionalIncome}
              prefix="$"
              placeholder="$0"
              thousandSeparator
              className={styles.formInput}
              name={YourEmployerVariable.AdditionalIncome}
              onBlur={onBlur}
              onChange={onChange}
              value={watcher[YourEmployerVariable.AdditionalIncome]}
              maxLength={ANNUAL_INCOME_MAX_LENGTH}
            />
            <p className={styles.additionalIncomeText}>
              Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it
              considered as a basis for repaying this obligation.
            </p>
          </div>
        </>
      )}

      <Button isLoading={isLoading} disabled={!isValid} onClick={handleContinue}>
        Save and Continue
      </Button>
    </FormContainer>
  );
};

export default EmploymentDetails;
