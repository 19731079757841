import React, { RefObject, useEffect, useRef, useState } from 'react';
import Button from 'components/Button';
import FormContainer from 'components/LoanForm/FormContainer';
import { ReactComponent as DefaultPaycheck } from 'images/default-paycheck.svg';
import { ReactComponent as ArrowIcon } from 'images/new-arrow-icon.svg';
import { ReactComponent as PlanneryLogo } from 'images/plannery-logo.svg';
import { ReactComponent as BankLogo } from 'images/bank-icon-small.svg';
import { ReactComponent as FastBill } from 'images/fast-bill.svg';
import clsx from 'clsx';
import CheckboxSmall from 'components/Checkbox/CheckboxSmall';
import { RoutePath } from 'enums/Routes';
import { useNavigate } from 'hooks/useNavigate';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { createPaymentAccount, getEmployerData, getPaymentAccount } from 'thunks';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { getPaymentAccountData } from 'selectors/paymentAccount';
import Loader from 'components/Loader';
import { RootState } from 'handlers';
import { ErrorType } from 'components/Error/ErrorType';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import { GetApplicationData } from 'handlers/applicationData';
import { getLoanProductName } from 'enums/ProfessionGroup';
import { getProfessionGroup } from 'selectors/professionGroup';

import styles from './RepaymentMethod.module.scss';

const termCheckboxes = [
  {
    id: 'depositAccountAgreement',
    label: 'Deposit Account Agreement including Truth in Savings and Regulation E disclosures',
    url: 'https://assets.planneryapp.com/deposit-account/plannery-emprise-account-agreement.pdf',
  },
  {
    id: 'privacyNotice',
    label: 'Privacy notice',
    url: 'https://assets.planneryapp.com/deposit-account/plannery-emprise-consumer-privacy-notice.pdf',
  },
];

const renderSteps = (application: GetApplicationData, logo: string | null) => {
  const deduction = application.amountPerPaycheck;

  const renderLogo = () => {
    if (logo) {
      return <img src={logo} alt="logo" className={styles.employerLogo} />;
    }

    return null;
  };

  if (application.fullDepositSwitch) {
    return (
      <div className={styles.stepsFullSwitch}>
        <div className={styles.step}>
          <h2>1.</h2>
          <div className={styles.lineWrapper}>
            <div className={styles.logoContainer}>{renderLogo() || <DefaultPaycheck />}</div>
            <div className={styles.label}>Your paycheck</div>
          </div>
        </div>
        <ArrowIcon className={styles.downArrowIcon} />
        <div className={styles.step}>
          <h2>2.</h2>
          <div className={styles.lineWrapper}>
            <div className={styles.logoContainer}>
              <FastBill />
            </div>
            <div className={styles.label}>
              Plannery direct
              <br />
              deposit account
            </div>
          </div>
        </div>
        <ArrowIcon className={clsx(styles.downArrowIcon)} />
        <div className={styles.step}>
          <h2>3.</h2>
          <div className={styles.stepWrapper}>
            <div className={styles.lineWrapper}>
              <div className={styles.logoContainer}>
                <PlanneryLogo className={styles.planneryLogo} />
              </div>
              <div className={styles.label}>
                <b>{formatMonetaryAmount(deduction, true)}</b> to your
                <br />
                loan payment
              </div>
            </div>
            <div className={clsx(styles.lineWrapper, styles.marginTop)}>
              <div className={styles.logoContainer} />
              <div className={styles.label}>
                <div className={styles.plusLabel}>
                  <div className={styles.inner}>Plus</div>
                </div>
              </div>
            </div>
            <div className={clsx(styles.lineWrapper, styles.marginTop)}>
              <div className={styles.logoContainer}>
                <BankLogo />
              </div>
              <div className={styles.label}>
                <b>Remainder</b> to {application.bankName} account ending in {application.accountMask}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.steps}>
      <div className={styles.item}>
        <div className={styles.logoContainer}>{renderLogo() || <DefaultPaycheck />}</div>
        <div className={styles.label}>
          <b>{formatMonetaryAmount(deduction, true)}</b>
          <br />
          from your paycheck
        </div>
      </div>
      <ArrowIcon className={styles.arrowIcon} />
      <div className={styles.item}>
        <div className={styles.logoContainer}>
          <FastBill />
        </div>
        <div className={styles.label}>
          Plannery
          <br />
          direct deposit account
        </div>
      </div>
      <ArrowIcon className={styles.arrowIcon} />
      <div className={styles.item}>
        <div className={styles.logoContainer}>
          <PlanneryLogo className={styles.planneryLogo} />
        </div>
        <div className={styles.label}>
          <b>{formatMonetaryAmount(deduction, true)}</b>
          <br />
          to your Plannery loan
        </div>
      </div>
    </div>
  );
};

const RepaymentMethod = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const { enableTreasuryPrimeIntegration } = useFlags();

  const navigate = useNavigate();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const bottomRef = useRef<HTMLDivElement>(null);

  const [secondStepEnabled, setSecondStepEnabled] = useState<boolean>(false);
  const [thirdStepEnabled, setThirdStepEnabled] = useState<boolean>(false);
  const [employerName, setEmployerName] = useState<string>('');
  const [logo, setLogo] = useState<string | null>(null);
  const [terms, setTerms] = useState<Record<string, boolean>>({
    depositAccountAgreement: false,
    privacyNotice: false,
  });
  const { professionGroup } = useSelector(getProfessionGroup);

  const { application } = useSelector(getApplicationData);
  const { status } = useSelector(getPaymentAccountData);

  const { isLoading: isFetchingEmployerData } = useSelector((state: RootState) => state.employers).getEmployerData;

  const allChecked = Object.values(terms).every((isChecked: boolean) => isChecked);
  const buttonLabel = thirdStepEnabled ? `Open direct deposit account` : `Continue`;
  const buttonDisabled = (thirdStepEnabled && !allChecked) || isFetchingEmployerData;

  const { isLoading } = status.createPaymentAccount;
  const { hasStarted: getPaymentAccountStarted, isLoading: getPaymentAccountLoading } = status.getPaymentAccount;

  const applicationId = application?.id;

  const employer1 = application?.employment.employer1;

  const manualUploadedLogo = employer1?.logo || null;
  const payrollProvider = employer1?.payrollProvider;
  const employerId = employer1?.linkItemId;

  const { isInvalid } = useSelector(getPaymentAccountData);

  const getLogo = async () => {
    if (payrollProvider && employerId) {
      const response = await dispatchWithUnwrap(getEmployerData({ payrollProvider, employerId }));
      setLogo(response.logo_url);
      setEmployerName(response.name);
    }
    if (!logo && manualUploadedLogo) {
      setLogo(manualUploadedLogo);
    }
    if (!employerName) {
      setEmployerName(employer1?.employerName!);
    }
  };

  const fetchPaymentAccount = async () => {
    if (applicationId && !getPaymentAccountStarted) {
      await dispatchWithUnwrap(getPaymentAccount({ applicationId }));
    }
  };

  const handleContinue = async () => {
    if (!secondStepEnabled) {
      analytics.track('Repayment Method Continue 1');
      setSecondStepEnabled(true);
    } else if (!thirdStepEnabled) {
      analytics.track('Repayment Method Continue 2');
      setThirdStepEnabled(true);
    } else {
      analytics.track('Create DDA Account Started');
      await dispatchWithUnwrap(createPaymentAccount({ applicationId: applicationId!, waitTimeMillis: 60000 }));
      handleNext();
    }
    scrollToView(bottomRef);
  };

  const scrollToView = (ref: RefObject<HTMLDivElement>) => {
    setTimeout(() => {
      ref?.current?.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }, 300);
  };

  useEffect(() => {
    if (!logo || !employerName) {
      getLogo();
    }
    fetchPaymentAccount();
  }, []);

  useEffect(() => {
    if (!enableTreasuryPrimeIntegration || application?.completedRepaymentAccountStep) {
      handleNext();
    }
  }, [enableTreasuryPrimeIntegration]);

  useEffect(() => {
    if (isInvalid) {
      navigate(RoutePath.Error, { state: { type: ErrorType.Default } });
    }
  }, [isInvalid]);

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer className={styles.container} title="Setup Repayment Method" subtitle="">
        {isFetchingEmployerData || getPaymentAccountLoading ? (
          <div className={styles.loaderContainer}>
            <Loader color="#795af7" />
          </div>
        ) : (
          <div className={styles.text}>
            Your Plannery {getLoanProductName(professionGroup)} is repaid via payroll deduction with {employerName}.
            This automates the payment process and allows us to offer you some of the best rates in the industry.
          </div>
        )}

        {secondStepEnabled && (
          <div
            className={clsx(styles.repaymentStep, {
              [styles.enabled]: secondStepEnabled,
            })}
          >
            <div className={styles.text}>
              To do this, we need to set you up with a direct deposit account where your payments will be sent.
            </div>
            <div className={styles.text}>Here's how payments will work:</div>

            {application !== undefined && <div className={styles.stepsContainer}>{renderSteps(application, logo)}</div>}
          </div>
        )}

        {thirdStepEnabled && (
          <div
            className={clsx(styles.repaymentStep, {
              [styles.enabled]: thirdStepEnabled,
            })}
          >
            <div className={styles.text}>
              By clicking the continue button below, we will open a direct deposit account with Emprise Bank, FDIC
              member. This will take about 30 seconds. Please review and accept the disclosures below.
            </div>

            <div className={styles.checkboxes}>
              {termCheckboxes.map((term) => (
                <CheckboxSmall
                  onChange={() => setTerms({ ...terms, [term.id]: !terms[term.id] })}
                  key={term.id}
                  disabled={isLoading}
                  checked={terms[term.id]}
                  label={
                    <a target="__blank" rel="noopener noreferrer" className={styles.anchor} href={term.url}>
                      {term.label}
                    </a>
                  }
                />
              ))}
            </div>
          </div>
        )}

        <Button isLoading={isLoading} disabled={buttonDisabled} onClick={handleContinue}>
          {buttonLabel}
        </Button>
      </FormContainer>
      <div ref={bottomRef} />
    </>
  );
};

export default RepaymentMethod;
