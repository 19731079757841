import React from 'react';

import { FlowComponentType } from 'routes/FlowRouter';
import FormNavigation from 'components/FormNavigation';

import PersonalDetails from './Steps/PersonalDetails/PersonalDetails';

const PersonalDetailsPage = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => (
  <>
    <FormNavigation {...navigationInfo} />
    <PersonalDetails handleNext={handleNext} />
  </>
);

export default PersonalDetailsPage;
