import { DebtSummary, GetApplicationData } from 'handlers/applicationData';

import { CardColor } from 'components/CardFlow/Customize/cardVersions';
import { ReferralProgram } from 'handlers/cardData';
import { ProfessionGroup } from 'enums/ProfessionGroup';
import { PartnerName } from 'enums/PartnerName';

import Api, { FetchMethodType } from './Api';
import { Employment, UtmTags } from './LoanOfferApi';

export interface CardApplyResponse {
  applicationId: string;
  borrowerId: string;
  referralLink: string;
  referredBy: string;
  applied: boolean;
  debtSummary: DebtSummary;
  debtConsolidationPossible: boolean;
  waitListPosition: number;
  originalWaitListPosition: number;
  totalDebt: number | null;
  cardColor: CardColor;
  borrowerCredentials: string;
  referralProgram: ReferralProgram;
  waitListLength: number;
  professionGroup: ProfessionGroup;
  totalAnnualIncome: number;
}

export interface CardApplyData {
  email: string;
  firstName: string;
  lastName: string;
  professionGroup: ProfessionGroup;
  credentials: string;
  phoneNumber: string;
  cardColor: CardColor;
  methodEntityId: string;
  cardReferredBy?: string;
  utm: UtmTags;
  httpReferrer?: string;
  resumeLink?: string;
  partner?: PartnerName;
  totalAnnualIncome: number;
  employment: Employment[];
  sessionToken?: string;
  timezone: string;
}

export interface CardApplyWithApplicationData {
  applicationId: string;
  httpReferrer?: string;
  referredBy?: string;
  resumeLink?: string;
}

export interface GetCardApplicationResponse {
  application: GetApplicationData;
  waitListLength: number;
}

export interface CardApi {
  apply(data: CardApplyData): Promise<CardApplyResponse>;
  applyWithLoanOffer(data: CardApplyWithApplicationData): Promise<CardApplyResponse>;
  getCardDataForLoanApplication(applicationId: string): Promise<GetCardApplicationResponse>;
}

export default class CardRestApi extends Api implements CardApi {
  async apply(data: CardApplyData): Promise<CardApplyResponse> {
    return this.fetch<CardApplyResponse>('/card/apply', {
      method: FetchMethodType.POST,
      body: data,
    });
  }

  async applyWithLoanOffer(data: CardApplyWithApplicationData): Promise<CardApplyResponse> {
    return this.fetch<CardApplyResponse>(`/card/apply/${data.applicationId}`, {
      method: FetchMethodType.POST,
      body: {
        referredBy: data.referredBy,
        httpReferrer: data.httpReferrer,
      },
    });
  }

  async getCardDataForLoanApplication(applicationId: string): Promise<GetCardApplicationResponse> {
    return this.fetch<GetCardApplicationResponse>(`/card/${applicationId}`);
  }

  async getWaitListLength(): Promise<{ waitListLength: number }> {
    return this.fetch<{ waitListLength: number }>('/card/waitListLength');
  }
}
