import React from 'react';
import { PayoffData } from 'api/StudentLoanApi';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { addMonths, formatMonthYear } from 'utils/dateUtils';

const HEADLINES = Object.freeze({
  none: <>Act Now! You could become debt free sooner</>,
  timeSavings: <>Act Now! You could become debt free</>,
  totalPayment: (
    <>
      Act Now!
      <br />
      You Could Save
    </>
  ),
  monthlyPayment: <>Act Now! You could reduce your payment by</>,
});

export const getRepaymentLabel = (
  currentPath: PayoffData,
  recommendedPath: PayoffData,
): {
  headline: JSX.Element;
  value: string | null;
} => {
  const timeSavingsText = getTimeSavingsText(currentPath.monthsToDebtFree, recommendedPath.monthsToDebtFree);
  const monthlyPaymentSavings = currentPath.monthlyPayment - recommendedPath.monthlyPayment;
  const totalPaymentSavings = currentPath.totalPayment! - recommendedPath.totalPayment;

  if (timeSavingsText.type === TimeSavingsType.Absolute) {
    return { headline: HEADLINES.timeSavings, value: timeSavingsText.value };
  }

  if (monthlyPaymentSavings >= 100) {
    return {
      headline: HEADLINES.monthlyPayment,
      value: `${formatMonetaryAmount(monthlyPaymentSavings)} per month`,
    };
  }

  if (totalPaymentSavings >= 10_000) {
    return {
      headline: HEADLINES.totalPayment,
      value: formatMonetaryAmount(totalPaymentSavings),
    };
  }

  if (timeSavingsText.type === TimeSavingsType.Relative) {
    return { headline: HEADLINES.timeSavings, value: timeSavingsText.value };
  }

  if (totalPaymentSavings >= 1_000) {
    return {
      headline: HEADLINES.totalPayment,
      value: formatMonetaryAmount(totalPaymentSavings),
    };
  }

  return { headline: HEADLINES.none, value: null };
};

export const getDebtFreeLabels = (
  currentPathMonthsToDebtFree: number,
  recommendedPathMonthsToDebtFree: number,
): { currentPathDebtFreeLabel: string; recommendedPathDebtFreeLabel: string } => {
  if (recommendedPathMonthsToDebtFree <= 36 || currentPathMonthsToDebtFree - recommendedPathMonthsToDebtFree < 12) {
    return {
      currentPathDebtFreeLabel: formatMonthYear(addMonths(new Date(), currentPathMonthsToDebtFree)),
      recommendedPathDebtFreeLabel: formatMonthYear(addMonths(new Date(), recommendedPathMonthsToDebtFree)),
    };
  }
  return {
    currentPathDebtFreeLabel: getMonthsText(currentPathMonthsToDebtFree),
    recommendedPathDebtFreeLabel: getMonthsText(recommendedPathMonthsToDebtFree),
  };
};

export enum TimeSavingsType {
  None,
  Absolute,
  Relative,
}

export const getTimeSavingsText = (
  currentPathMonthsToDebtFree: number,
  recommendedPathMonthsToDebtFree: number,
): { type: TimeSavingsType; value: string } => {
  if (recommendedPathMonthsToDebtFree < 0) {
    return { type: TimeSavingsType.Absolute, value: 'This month' };
  }

  const monthsUntilEndOfYear = 12 - new Date().getMonth();
  if (recommendedPathMonthsToDebtFree < monthsUntilEndOfYear) {
    return { type: TimeSavingsType.Absolute, value: 'This year' };
  }
  if (recommendedPathMonthsToDebtFree < monthsUntilEndOfYear + 12) {
    return { type: TimeSavingsType.Absolute, value: 'Next year' };
  }

  const monthDiff = currentPathMonthsToDebtFree - recommendedPathMonthsToDebtFree;

  if (!monthDiff || Number.isNaN(monthDiff) || monthDiff <= 0) {
    return { type: TimeSavingsType.None, value: '' };
  }

  if (monthDiff === 1) {
    return { type: TimeSavingsType.Relative, value: `1 month earlier` };
  }

  if (monthDiff < 12) {
    return { type: TimeSavingsType.Relative, value: `${monthDiff} months earlier` };
  }

  if (monthDiff < 24) {
    return { type: TimeSavingsType.Relative, value: '1 year earlier' };
  }

  return { type: TimeSavingsType.Relative, value: `${Math.floor(monthDiff / 12)} years earlier` };
};

export const getMonthsText = (months: number): string => {
  if (Number.isNaN(months)) {
    return 'Sooner';
  }

  if (months <= 0) {
    return 'This month;';
  }
  if (months === 1) {
    return '1 month';
  }
  if (months < 36) {
    return `${months} months`;
  }
  return `${Math.floor(months / 12)} years`;
};
